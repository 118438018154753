import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Container, Col, Row, ListGroup } from 'react-bootstrap';
import styles from './AddPriceModal.module.css';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    width: '90%',
    maxWidth: '600px',
    height: '90%',
    maxHeight: '600px',
    padding: '20px',
    margin: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const PriceModal = ({ isOpen, onRequestClose, selectedPlaceProp }) => {
  const [localPlaces, setLocalPlaces] = useState(null);
  const [updatedPlaces, setUpdatedPlaces] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      let token = localStorage.getItem('token')
      const response = await fetch(`/api/getUserPlaces`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      let data = await response.json()
      setLocalPlaces(data['places'])
    };

    if(isOpen){
      fetchData()
      .catch(console.error);
    }
  }, [isOpen]);

  const handlePriceChange = (placeId, newPrice) => {
    setUpdatedPlaces(prevPlaces => ({
      ...prevPlaces,
      [placeId]: {
        place_id: parseInt(placeId),
        price: parseFloat(newPrice),
        in_stock: prevPlaces[placeId]?.in_stock // Retain existing in_stock value
      }
    }));
    
    setLocalPlaces(prevPlaces =>
      prevPlaces?.map(place =>
        place.place_id === placeId ? { ...place, price: newPrice } : place
      )
    );
  };
  const handleToggle = (placeId, value) => {
    setLocalPlaces(prevPlaces =>
      prevPlaces.map(place =>
        place.place_id === placeId
          ? { ...place, in_stock: value === null ? false : value }
          : place
      )
    );
  
    setUpdatedPlaces(prevPlaces => ({
      ...prevPlaces,
      [placeId]: {
        place_id: parseInt(placeId),
        in_stock: value,
        price: prevPlaces[placeId]?.price // Retain existing price value
      }
    }));
  };

  const handleSave = async () => {
    onRequestClose();
    let token = localStorage.getItem('token')
    try {
       await fetch(`/api/updatePlaces`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({"places" : Object.values(updatedPlaces)})
      });
    } catch (error) {
      console.error('Error:', error);
    }
    setUpdatedPlaces({})
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Price Modal"
      style={customStyles}
    >
      <h2 className={styles.title}>Update Prices</h2>
      <div className={styles.content}>
        <div className={styles.list}>
          <ListGroup className="places-list">
            <ListGroup.Item className={styles.header}>
              <Container>
                <Row>
                  <Col xs={6}><h5>Place</h5></Col>
                  <Col xs={3} className="text-center"><h5>Price</h5></Col>
                  <Col xs={3} className="text-center"><h5>In Stock</h5></Col>
                </Row>
              </Container>
            </ListGroup.Item>
            {localPlaces?.map((place) => (
              <ListGroup.Item key={place.place_id}>
                <Row className="d-flex justify-content-start align-items-center py-2">
                  <Col xs={6} className="text-black">
                    <div>{place.store_name}</div>
                    <small className="text-muted">
                      {place.address}, {place.city}, {place.state} {place.zip}
                    </small> 
                  </Col>
                  <Col xs={3} className="text-center">
                    <input 
                      type="text"
                      value={place.price || ''}
                      onChange={(e) => handlePriceChange(place.place_id, e.target.value)}
                      className={styles.priceInput}
                    />
                  </Col>
                  <Col xs={3} className="text-center">
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        checked={place.in_stock}
                        onChange={(e) => handleToggle(place.place_id, e.target.checked)}
                      />
                      <span className={styles.slider}></span>
                    </label>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </div>
      <div className={styles.footer}>
        <button className={styles.button} onClick={handleSave}>Save Changes</button>
        <button className={styles.button} onClick={onRequestClose}>Cancel</button>
      </div>
    </Modal>
  );
};

export default PriceModal;