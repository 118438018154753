import React, { useState } from "react";
import { Container, Form, ListGroup, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import './PlacesList.css';

const PlacesList = ({ places, onPlaceSelect, selectedPlace }) => {
  const [filterText, setFilterText] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handlePlaceClick = (place) => {
    onPlaceSelect(place);
  };

  const filteredPlaces = places && places.length > 0
    ? places
      .filter(place => place.store_name.toLowerCase().includes(filterText.toLowerCase()))
      .sort((a, b) => {
        const priceA = parseFloat(a.price);
        const priceB = parseFloat(b.price);

        if (priceA === 0) return 1; 
        if (priceB === 0) return -1;

        if (!isNaN(priceA) && !isNaN(priceB)) {
          return sortDirection === "asc" ? priceA - priceB : priceB - priceA;
        } else if (!isNaN(priceA)) {
          return sortDirection === "asc" ? -1 : 1;
        } else if (!isNaN(priceB)) {
          return sortDirection === "asc" ? 1 : -1;
        } else {
          return 0;
        }
      })
    : [];

  // Function to abbreviate place name
  const abbreviate = (name) => {
    if (name.length > 25) {
      return name.substring(0, 15) + "...";
    }
    return name;
  };

  return (
    <div>
      <Form>
        <Form.Control
          type="text"
          placeholder="Filter places"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </Form>
      <ListGroup className="places-list">
        <ListGroup.Item
          className="places-list-header"
          onClick={toggleSortDirection}
          style={{ cursor: "pointer" }}
        >
          <Container>
            <Row>
              <Col xs={10}>
                <h5>Place</h5>
              </Col>
              <Col xs={2} className="text-right">
                <h5>Price</h5>
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>
        {filteredPlaces.map((place, index) => (
          <ListGroup.Item 
          key={index} 
          onClick={() => handlePlaceClick(place)} 
          className={`place-item ${selectedPlace && selectedPlace.place_id === place.place_id ? 'selected' : ''}`}
        >
            
            <Container>
            <Row className="align-items-center">
              <Col xs={9}>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-${index}`}>
                      {place.store_name} - {place.address}
                    </Tooltip>
                  }
                  placement="top"
                >
                  <div className="place-main-info d-flex align-items-center">
                    <span className="place-name font-weight-bold text-truncate mr-1">
                      {abbreviate(place.store_name)}
                    </span>
                    <span className="place-separator text-muted mx-1">-</span>
                    <span className="place-neighborhood text-secondary text-truncate">
                      {place.name}
                    </span>
                  </div>
                </OverlayTrigger>
                <div className="place-address small text-muted">
                  {place.address}, {place.city} {place.zip}
                </div>
                <div className="place-stock mt-1">
                  In Stock: {' '}
                  <span className={`font-weight-bold ${
                    place.in_stock === true
                      ? 'text-success'
                      : place.in_stock === false
                      ? 'text-danger'
                      : 'text-warning'
                  }`}>
                    {place.in_stock === true
                      ? 'Yes'
                      : place.in_stock === false
                      ? 'No'
                      : 'Unknown'}
                  </span>
                </div>
              </Col>
              <Col xs={3} className="text-right">
                <div className="place-price h5 mb-0">
                  ${place.price?.toFixed(2)}
                </div>
              </Col>
            </Row>
            </Container>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default PlacesList;