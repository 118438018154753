import React, { useEffect, useState, useRef, useCallback } from "react";
import debounce from "lodash.debounce";
import { createRoot } from "react-dom/client";
import InfoWindowContent from "./InfoWindowContent";

const google = window.google;
await google.maps.importLibrary("marker");
const { AdvancedMarkerElement } = google.maps.marker;
const { Map, LatLngBounds } = google.maps;

export default function MapComponent(props) {
  const [map, setMap] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const infoWindowRef = useRef(null);
  const infoWindowContentRootRef = useRef(null);
  const previousSelectedPlaceRef = useRef(null);
  const markersRef = useRef([]);
  const mapRef = useRef(null);
  const isFittingBoundsRef = useRef(false);

  useEffect(() => {
    const initMap = () => {
      if (mapRef.current) return;

      mapRef.current = new Map(document.getElementById("map"), {
        center: { lat: 42.3605, lng: -71.0591 },
        zoom: 13,
        mapId: "4504f8b37365c3d0",
        gestureHandling: 'greedy',
        disableDefaultUI: true
      });

      setMap(mapRef.current);

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      }
    };

    if (typeof window.google !== 'undefined') {
      initMap();
    } else {
      window.initMap = initMap;
    }
  }, []);

  useEffect(() => {
    if (map && userLocation) {
      map.setCenter(userLocation);
      map.setZoom(15);

      new google.maps.Marker({
        position: userLocation,
        map: map,
        title: "Your Location"
      });

      const bounds = map.getBounds();
      if (bounds && props.handleBoundsChange) {
        props.handleBoundsChange(bounds);
      }
      const newCenter = map.getCenter();
      if(newCenter){
        props.handleCenterChange(newCenter.lng(), newCenter.lat())
      }
    }
  }, [map, userLocation]);

  const openInfoWindow = (place) => {
    const newCenter = { lat: place.lat, lng: place.lng };
    
    if (!infoWindowRef.current) {
      infoWindowRef.current = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(0, -40)
      });

      const infoWindowContentDiv = document.createElement('div');
      infoWindowContentRootRef.current = createRoot(infoWindowContentDiv);
      
      infoWindowRef.current.setContent(infoWindowContentDiv);

      infoWindowRef.current.addListener('closeclick', () => {
        if (props.onPlaceDeselect) {
          props.onPlaceDeselect();
        }
      });
    }

    infoWindowContentRootRef.current.render(
      <InfoWindowContent 
        mark={place} 
        setModalIsOpen={props.setModalIsOpen} 
        setSelectedPlace={props.setSelectedPlaceProp} 
      />
    );

    infoWindowRef.current.setPosition(newCenter);
    infoWindowRef.current.open(map);

    map.panTo(newCenter);
    map.setZoom(17);
  };

  useEffect(() => {
    if (map && props.selectedPlaceProp && props.selectedPlaceProp['place_id'] > 0 &&
        props.selectedPlaceProp !== previousSelectedPlaceRef.current) {
      
      previousSelectedPlaceRef.current = props.selectedPlaceProp;
      
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }
      
      openInfoWindow(props.selectedPlaceProp);
    }
  }, [props.selectedPlaceProp, map]);
  
  useEffect(() => {
    if (map) {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
  
      if (props.places) {
        props.places.forEach(place => {
          const priceTag = document.createElement("div");
          priceTag.className = "price-tag";
          priceTag.textContent = "$" + place.price?.toFixed(2);
  
          // Determine the color based on in_stock status
          let color;
          if (place.in_stock === true) {
            color = "#4CAF50"; // Green
            priceTag.style.backgroundColor = color;
          } else if (place.in_stock === false) {
            color = "#F44336"; // Red
            priceTag.style.backgroundColor = color;
          } else {
            //color = "#0000FF"; // Yellow (for null or undefined)
          }
  
          // Apply the color to the price tag
          const marker = new AdvancedMarkerElement({
            map: map,
            position: { lat: place.lat, lng: place.lng },
            content: priceTag,
          });
  
          markersRef.current.push(marker);
  
          marker.addListener("click", () => {
            if (infoWindowRef.current) {
              infoWindowRef.current.close();
            }
            openInfoWindow(place);
            if (props.onPlaceSelect) {
              props.onPlaceSelect(place);
            }
          });
        });
      }
    }
  }, [props.places, map]);

  const fitBounds = useCallback((map, places) => {
    if (!map || !places || places.length === 0) return;

    isFittingBoundsRef.current = true;

    const bounds = new LatLngBounds();
    places.forEach(place => {
      bounds.extend(new google.maps.LatLng(place.lat, place.lng));
    });

    map.fitBounds(bounds);

    // Calculate the appropriate zoom level
    const boundsWidth = bounds.getNorthEast().lng() - bounds.getSouthWest().lng();
    const boundsHeight = bounds.getNorthEast().lat() - bounds.getSouthWest().lat();
    const diagonalDistance = Math.sqrt(boundsWidth * boundsWidth + boundsHeight * boundsHeight);

    let zoom;
    if (diagonalDistance < 0.01) {
      zoom = 17;  // For very small areas
    } else if (diagonalDistance < 0.1) {
      zoom = 16;  // For small areas
    } else if (diagonalDistance < 0.5) {
      zoom = 14;  // For medium areas
    } else {
      zoom = 13;  // For large areas
    }

    map.setZoom(Math.min(zoom, map.getZoom()));

    setTimeout(() => {
      isFittingBoundsRef.current = false;
    }, 1000);
  }, []);

  useEffect(() => {
    if (map && props.selectedNeighborhood && props.places && props.places.length > 0) {
      fitBounds(map, props.places);
    }
  }, [map, props.selectedNeighborhood, props.places, fitBounds]);

  const debouncedHandleBoundsChange = 
  useCallback(debounce((bounds) => {
      if (props.handleBoundsChange && !isFittingBoundsRef.current) {
        props.handleBoundsChange(bounds);
        console.log("debounce??")
      }
    }, 300, {'leading': true}), []);

  useEffect(() => {
    if (map) {
      map.addListener("bounds_changed", () => {
        if (!isFittingBoundsRef.current) {
          const bounds = map.getBounds();
          if (bounds) {
            debouncedHandleBoundsChange(bounds);
          }
          const newCenter = map.getCenter();
          if(newCenter){
            props.handleCenterChange(newCenter.lng(), newCenter.lat())
          }
        }
      });

      map.addListener("dragend", () => {
        if (props.handleDrag) {
          props.handleDrag();
          const newCenter = map.getCenter();
          props.handleCenterChange(newCenter.lng(), newCenter.lat())
        }
      });
    }

    return () => {
      if (map) {
        google.maps.event.clearListeners(map, "bounds_changed");
        google.maps.event.clearListeners(map, "dragend");
      }
    };
  }, [map]);

  return <div style={{ width: '100%', height: 'calc(100vh - 100px)' }} id="map"></div>;
}