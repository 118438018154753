import logo from './logo.svg';
import './App.css';
import MapComponent from './Components/Map/Map'
import MapWithList from './Components/LandingPage/LandingPage'

function App() {
  return (
    <div className="App">
      <MapWithList></MapWithList>
    </div>
  );
}

export default App;
