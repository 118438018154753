import React, { useEffect, useState, useCallback } from "react";
import MapComponent from "../Map/Map";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import PlacesList from "../PlacesList/PlacesList";
import PriceModal from "../Modals/AddPriceModal";
import useMediaQuery from "../Map/useMediaQuery";
import './LandingPage.css'; 
import LoginModal from "../Modals/LoginModal";

const initialLng = -71.04949;//-71.0591;
const initialLat = 42.33343 //42.3605;
const initialMeters = 5000;

const MapWithList = () => {
  const [currentPlaces, setCurrentPlaces] = useState([]);
  const [currentNeighborhoods, setCurrentNeighborhoods] = useState([]);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
  const [lng, setLng] = useState(initialLng);
  const [lat, setLat] = useState(initialLat);
  const [meters, setMeters] = useState(initialMeters);
  const [modalIsOpen, setInventoryModalIsOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [zoom, setZoom] = useState(15);
  const [bounds, setBounds] = useState(null);
  const [isMapView, setIsMapView] = useState(true);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const openLoginModal = () => setLoginModalIsOpen(true);
  const closeLoginModal = () => setLoginModalIsOpen(false);
  const login = () => setIsLoggedIn(true)
  const logout = () => setIsLoggedIn(false)

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      verifyToken(token);
    }
  }, []);
  const verifyToken = async (token) => {
    try {
      const response = await fetch('/api/verifyToken', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        setIsLoggedIn(true);
      } else {
        // If token is invalid, clear it
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error("Error verifying token:", error);
    }
  };
  
  
  useEffect(() => {
    async function fetchPlaces() {
      const url = `/api/getPlaces?lng=${lng}&lat=${lat}&meters=${meters}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setCurrentPlaces(data["Data"]);
      } catch (error) {
        console.error("Error fetching places:", error);
      }
    }

    async function fetchPlacesByNeighborhood() {
      if (selectedNeighborhood) {
        const neighborhood_id = selectedNeighborhood.neighborhood_id;
        const url = `/api/getPlacesByNeighborhoodId?&neighborhood_id=${neighborhood_id}`;
        try {
          const response = await fetch(url);
          const data = await response.json();
          setCurrentPlaces(data["Data"]);
        } catch (error) {
          console.error("Error fetching places by neighborhood:", error);
        }
      }
    }

    if (selectedNeighborhood) {
      fetchPlacesByNeighborhood();
    }
    else{
      fetchPlaces();
      //fetchPlacesByBounds();
    }
  }, [selectedNeighborhood]);

  useEffect(() => {
    async function fetchPlacesByBounds(){
      if(bounds){
        const url = `/api/getPlacesByBounds?east=${bounds['east']}&north=${bounds['north']}&south=${bounds['south']}&west=${bounds['west']}`
        try {
          const response = await fetch(url);
          const data = await response.json();
          setCurrentPlaces(data["Data"]);
        } catch (error) {
          console.error("Error fetching places by bounds:", error);
        }
      }
    }
    console.log("bounds cahning??")
    fetchPlacesByBounds();

  }, [bounds])
  useEffect(() => {
    async function fetchNeighborhoods() {
      const url = `/api/getNeighborhoods?lng=${lng}&lat=${lat}&meters=${meters}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setCurrentNeighborhoods(data["Data"]);
      } catch (error) {
        console.error("Error fetching Neighborhoods:", error);
      }
    }
    fetchNeighborhoods();
  }, [bounds]);

  // Callbacks 
  const handleCenterChange = (lng, lat) => {
    setLng(lng);
    setLat(lat);
  };

  const handleBoundsChange = (newBounds) => {
    setBounds(newBounds.toJSON());
  };

  const handleDrag = () => {
    setSelectedNeighborhood(null);
  };

  const openInventoryModal = () => setInventoryModalIsOpen(true);
  const closeInventoryModal = () => setInventoryModalIsOpen(false);

  const handleNeighborhoodSelect = (neighborhood) => {
    setSelectedNeighborhood(neighborhood);
    setLat(neighborhood.lat);
    setLng(neighborhood.lng);
    setZoom(15);
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setLat(place.lat)
    setLng(place.lng)
    setShowInfoWindow(true);
  };

  const handlePlaceDeselect = () => {
    setSelectedPlace(null);
  }

  const toggleView = () => {
    setIsMapView(!isMapView);
  };



  const handleLoginSuccess = (userData) => {
    login();
    setUser(userData);
    openInventoryModal();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem('token');
  };

  //fetch functions
  const fetchCheapestPlaces = async () => {
    try {
      const url = `/api/getCheapestPlacesByDistance?lng=${lng}&lat=${lat}&miles=1&total=1`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.Data && data.Data.length > 0) {
        setCurrentPlaces(data.Data);
        setSelectedPlace(data.Data[0]);
        setLat(data.Data[0].lat);
        setLng(data.Data[0].lng);
        setShowInfoWindow(true);
      }
    } catch (error) {
      console.error("Error fetching cheapest places:", error);
    }
  };

  return (
    <div style={{ overflow: 'hidden', width: '100%', height: '100vh' }}>
      <div className="header-wrapper">
        <h1 className="header-title" id="zynfo-title">ZYNFO</h1>
        <header className="header-container">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            zIndex: 1
          }}>
      <Dropdown>
        <Dropdown.Toggle 
          variant="primary" 
          id="dropdown-basic" 
          size={isMobile ? "sm" : ""}
          style={isMobile ? { fontSize: '0.8rem', padding: '0.25rem 0.5rem' } : {}}
        >
          {selectedNeighborhood ? selectedNeighborhood.name : "Neighborhood"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {currentNeighborhoods.map((neighborhood) => (
            <Dropdown.Item
              key={neighborhood.neighborhood_id}
              onClick={() => handleNeighborhoodSelect(neighborhood)}
            >
              {neighborhood.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <button 
        onClick={fetchCheapestPlaces} 
        className={`btn btn-info ${isMobile ? 'btn-sm' : ''}`}
        style={{
          ...(isMobile ? { fontSize: '0.8rem', padding: '0.25rem 0.5rem' } : {}),
          whiteSpace: 'nowrap'
        }}
      >
        Cheapest Near You
      </button>
    </div>

    <div className="button-wrapper" style={{ zIndex: 1 }}>
      {isLoggedIn ? (
        <button 
          onClick={openInventoryModal} 
          className={`btn btn-primary ${isMobile ? 'btn-sm' : ''}`}
          style={isMobile ? { fontSize: '0.8rem', padding: '0.25rem 0.5rem' } : {}}
        >
          Add Price
        </button>
      ) : (
        <button 
          onClick={openLoginModal} 
          className={`btn btn-secondary ${isMobile ? 'btn-sm' : ''}`}
          style={isMobile ? { fontSize: '0.8rem', padding: '0.25rem 0.5rem' } : {}}
        >
          Vendor Login
        </button>
      )}
    </div>
  </header>
</div>

      <Container fluid >
        {isMobile && (
          <div className="text-center my-2">
            <Button onClick={toggleView}>
              {isMapView ? "Show List" : "Show Map"}
            </Button>
          </div>
        )}
        <Row>
          {isMobile ? (
            isMapView ? (
              <Col xs={12} className="map-container">
                <MapComponent
                   style={{ maxHeight: "calc(100vh - 100px)"}}
                   places={currentPlaces}
                   center={{ lat: lat, lng: lng }}
                   handleCenterChange={handleCenterChange}
                   handleBoundsChange={handleBoundsChange}
                   selectedNeighborhood={selectedNeighborhood}
                   zoom={zoom}
                   handleDrag={handleDrag}
                   setModalIsOpen={setInventoryModalIsOpen}
                   setSelectedPlaceProp ={setSelectedPlace}
                   selectedPlaceProp={selectedPlace}
                   isMobile={isMobile}
                   showInfoWindow={showInfoWindow}
                   setShowInfoWindow={setShowInfoWindow}
                   onPlaceDeselect={handlePlaceDeselect}
                   onPlaceSelect={handlePlaceSelect}
                />
              </Col>
            ) : (
              <Col xs={12}>
                <div style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}>
                  <PlacesList
                    places={currentPlaces}
                    neighborhoods={currentNeighborhoods}
                    onPlaceSelect={handlePlaceSelect}
                    selectedPlace={selectedPlace}
                  />
                </div>
              </Col>
            )
          ) : (
            <>
              <Col md={3}>
                <div style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}>
                  <PlacesList
                    places={currentPlaces}
                    neighborhoods={currentNeighborhoods}
                    onPlaceSelect={handlePlaceSelect}
                    selectedPlace={selectedPlace}
                  />
                </div>
              </Col>
              <Col md={9}>
                <MapComponent
                  places={currentPlaces}
                  center={{ lat: lat, lng: lng }}
                  handleCenterChange={handleCenterChange}
                  selectedNeighborhood={selectedNeighborhood}
                  zoom={zoom}
                  handleDrag={handleDrag}
                  setModalIsOpen={setInventoryModalIsOpen}
                  setSelectedPlaceProp ={setSelectedPlace}
                  selectedPlaceProp={selectedPlace}
                  handleBoundsChange={handleBoundsChange}
                  setShowInfoWindow={setShowInfoWindow}
                  onPlaceDeselect={handlePlaceDeselect}
                  onPlaceSelect={handlePlaceSelect}
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
      <PriceModal
        isOpen={modalIsOpen}
        onRequestClose={closeInventoryModal}
        places={currentPlaces}
        selectedPlaceProp={selectedPlace}
      />
      <LoginModal
        login={login}
        handleLoginSuccess={handleLoginSuccess}
        isOpen={loginModalIsOpen}
        onRequestClose={closeLoginModal}
      />
    </div>
  );
};

export default MapWithList;
