// InfoWindowContent.js
import React from "react";

const InfoWindowContent = ({ mark }) => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    width: '150px', // Adjust this width as needed
    //height: '50px'
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const cellStyle = {
    padding: '2px 0'
  };

  const keyStyle = {
    ...cellStyle,
    fontWeight: 'bold',
    textAlign: 'right',
    paddingRight: '4px',
    whiteSpace: 'nowrap',
  };

  const valueStyle = {
    ...cellStyle,
    textAlign: 'left',
    whiteSpace: 'nowrap',
  };

  return (
    <div style={containerStyle}>
      <div style={{ textAlign: 'center', marginBottom: '10px', }}>
        <div style={{fontWeight: 'bold'}}>{mark.store_name}</div>
        <div>{mark.address}, {mark.city}, {mark.state} {mark.zip}</div>
      </div>
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={keyStyle}>Price:</td>
            <td style={valueStyle}>${mark.price?.toFixed(2)}</td>
          </tr>
          <tr>
            <td style={keyStyle}>In Stock:</td>
            <td style={valueStyle}>
              {mark.in_stock === null ? "Unknown" :
               mark.in_stock === true ? "Yes" :
               "No"}
            </td>
          </tr>
          {mark.edit_date && (
            <tr>
              <td style={keyStyle}>Last Update:</td>
              <td style={valueStyle}>{new Date(mark.edit_date).toLocaleDateString()}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InfoWindowContent;
